<template>
	<v-list>
		<v-list-tile v-for="(file, index) in files" :key="index" avatar>
			<v-list-tile-avatar>
				<v-icon :color="file.color">{{ file.icon }}</v-icon>
			</v-list-tile-avatar>
			<v-list-tile-content>
				<v-list-tile-title v-text="file.name" />
			</v-list-tile-content>
			<v-list-tile-action v-if="deletable">
				<v-btn icon @click="onDelete(index)">
					<v-icon color="error">cancel</v-icon>
				</v-btn>
			</v-list-tile-action>
		</v-list-tile>
	</v-list>
</template>

<script>
import { iconography } from '@/helpers/files'

export default {
	name: 'FileList',
	props: {
		deletable: {
			default: true,
			required: false,
			type: Boolean
		},
		value: {
			required: true,
			type: Array,
			validator: value => {
				return value.every(file => file instanceof File)
			}
		}
	},
	computed: {
		files: function () {
			return this.value.map(file => {
				if (file.type && file.type.trim().length > 0) {
					let splittedName = null
					if (file.name) {
						splittedName = file.name.split('.')
					}

					const extension = splittedName[splittedName.length - 1].toLowerCase()
					let fileType = iconography[extension]
					if (!fileType) {
						fileType = iconography.default
					}
					file.color = fileType.color
					file.icon = fileType.icon
				}
				return file
			})
		}
	},
	methods: {
		emitInput: function (files) {
			/**
			 * @event input
			 *
			 * @property {Array} files
			 */
			this.$emit('input', files)
		},
		onDelete: function (index) {
			const files = [...this.value]
			files.splice(index, 1)
			this.emitInput(files)
		}
	}
}
</script>
